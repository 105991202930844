require("rails-ujs").start()
import $ from 'jquery';
import * as bootstrap from 'bootstrap';
import 'cocoon';
import 'tinymce';
import 'datatables.net';
import 'select2';

var dataTable = null

$( function () {
  $('#myModal').on('shown.bs.modal', function () {
    $('#myInput').trigger('focus')
  })
});

$( function () {
  dataTable = $('#events_table').DataTable(
    {
      stateSave: true
    }
  );
  dataTable = $('#sites_table').DataTable(
    {
      stateSave: true
    }
  );
});
$(function() { 
  $(".sites_list").select2(); 
  $('form').bind('cocoon:after-insert', function() {
    $(".sites_list").select2();
  });  
});

// $(function () {
//   $('[data-toggle="tooltip"]').tooltip()
// });

